<template>
  <div class="calendar-create-event scroll d-flex gap-1 gap-md-3 justify-content-sm-between custom-calendar">
    <div class="column-calendar-header px-2 py-3  p-md-3" v-for="(day, index) in arrayDaysData" :key="index">
      <div class="custom-weekday-label create-calendar fs-6 fw-bold px-1 px-md-4 py-1 d-flex flex-column gap-1 align-items-center" :class="day.isToday ? 'column-calendar-header-today' : 'column-calendar-header-rest'">
        <span >{{ day.isToday ? 'TODAY' :  day?.month.toUpperCase() }}</span>
        <div class="d-flex gap-1 gap-md-4 justify-content-center"><span>{{ day?.label.substr(0, 3) }} </span>  <span>{{ day?.day }} </span></div>
      </div>
      <div class="mt-2 bg-white d-flex align-items-center p-2 gap-2 pointer select-class" :class="time?.state"
        v-for=" (time, index2) in day.times" :key="index2" style="border-radius: 10px" @click="
          (e) => {
            if (!isSelecting) {
              selectTimeNow(
                time.time,
                day.date,
                index2,
                'now',
                index,
                getLastTime(day.date, time.time)
              );
            }
          }
        ">
        <i class="pi pi-clock"></i>
        <span style="font-size: 15px">{{ time.time.substr(0, 5) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";
import { ref, watchEffect, computed, onUpdated } from "vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "./calendar.scss";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { useStore } from "vuex";
export default {
  components: {
    VueCal,
  },
  props: {
    type: {
      type: String,
      default: "time",
    },
    selectTime: {
      type: Function,
      required: true,
    },
    scheduleds: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const store = useStore();
    const isSelecting = ref(false);
    const arrayDaysData = ref([]);
    const fiveDaysInfo = ref({});
    for (let i = 0; i < 7; i++) {
      const actualDat = moment(store.getters['shared/currentTimeTimezone']).add(i, 'd')
      const month = actualDat.format("MMMM");
      const day = actualDat.format("DD");
      const dayName = actualDat.format("dddd");
      if (dayName !== "Sunday")
        fiveDaysInfo.value[dayName] = {
          month: month,
          day: day,
          date: actualDat.format("YYYY-MM-DD HH:mm:ss"),
        };
    }
    const selectTimeNow = (time, date, index, type, day, dateNext) => {
      const { payment_date_end } = store.getters["students/studentInformation"];
      isSelecting.value = true;
      const thisDate = moment(date).format('YYYY/MM/DD')
      const endDate = moment(payment_date_end).format('YYYY/MM/DD')
      if (moment(endDate).isSameOrAfter(moment(thisDate))) {
        arrayDaysData.value[day].times[index].state =
          arrayDaysData.value[day].times[index].state === "select-class"
            ? "select-class-active"
            : "select-class";

        arrayDaysData.value[day].times[index].state === "select-class-active"
          ? props.selectTime(time, date, dateNext, "add", "")
          : props.selectTime(time, date, dateNext, "delete", index);
      } else {
        const notification = { isOpen: true };
        notification.status = "error";
        notification.message = `Your subscription is canceled and I can't scheduled a class after that date, please contact us for more information.`;
        store.commit("shared/uiNotificationStatus", notification);
        store.dispatch("shared/resetNotificationStatus");
      }

      isSelecting.value = false;
    };


    const getLastTime = (date, time) => {
      const new_time = moment(time, "HH:mm:ss ").add(30, "m")
      return new_time.format("HH:mm");
    }

    watchEffect(() => {
      let scheduledClass = store.getters["students/scheduledClass"];
      let teachersAll = store.getters["students/teachersAll"]
      if (props.type === "time" && scheduledClass.data.length === 0 && arrayDaysData.value.length === 0) {
        Object.entries(props.scheduleds).forEach(([key, value]) => {
          if (key !== "Sunday") {
            const newValue = [];
            value.forEach(time => {
              newValue.push({
                time: `${time.substr(0, 5)}`,
                state: "select-class",
              });
            });
            const isToday = moment(store.getters['shared/currentTimeTimezone']).format("YYYY-MM-DD HH:mm:ss") === fiveDaysInfo.value[key].date ;
            arrayDaysData.value.push({ label: key, times: newValue, day: fiveDaysInfo.value[key].day, date: fiveDaysInfo.value[key].date, month: fiveDaysInfo.value[key].month , isToday });
          }
        })
      }

      if (teachersAll.search !== "" && props.type !== "time" && scheduledClass.data.length === 0 && arrayDaysData.value.length === 0) {


        Object.entries(props.scheduleds).forEach(([key, value]) => {
          if (key !== "Sunday") {
            const newValue = [];
            value.forEach(time => {
              newValue.push({
                time: `${time.substr(0, 5)}`,
                state: "select-class",
              });
            });
            const isToday = moment(store.getters['shared/currentTimeTimezone']).format("YYYY-MM-DD HH:mm:ss") === fiveDaysInfo.value[key].date ;
            arrayDaysData.value.push({ label: key, times: newValue, day: fiveDaysInfo.value[key].day, date: fiveDaysInfo.value[key].date, month: fiveDaysInfo.value[key].month , isToday});
          }
        })
      }

      arrayDaysData.value.map((item, index) => {
        if(!item.times.length) 
        arrayDaysData.value.splice(index, 1);
      })
    });

    return {
      isSelecting,
      getLastTime,
      format,
      selectTimeNow,
      arrayDaysData,
      moment,
    };
  },
};
</script>
