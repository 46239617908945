<template>
  <Dialog
    class="bg-white p-4 border-r-10 bl-shadow"
    v-model:visible="classModal.isOpen"
    :modal="false"
    :showHeader="false"
  >
    <div class="d-flex justify-content-end mb-3">
      <i
        class="pi pi-times fs-5 pointer text-primary-1 fw-bold"
        @click="modalClass"
      ></i>
    </div>
    <div class="d-flex align-items-center justify-content-between gap-3 mb-1">
      <h2 class="fw-bold fs-5 mb-0">{{classModal.data?.student_name}} {{classModal.data?.student_last_name}}</h2>
      <p class="mb-0">{{formatDate(classModal.data?.startText + classModal.data?.endText,'modalClassTeacher')}}</p>
    </div>
    <p class="mb-2">{{classModal.data?.level_name.toUpperCase()}}</p>
    <div class="d-flex align-items-center justify-content-between gap-3 mb-3">
      <p class="fw-bold fs-5 mb-0">Goal’s Class:</p>
      <div class="p-1 bg-accent-1 rounded-pill px-3 text-white">
        <p class="mb-0">{{classModal.data?.comment_goal}}</p>
      </div>
    </div>
    <div class="my-3 field-blue">
      <Textarea
        :disabled="true"
        :autoResize="true"
        rows="3"
        class="w-100 border-r-10 mt-2"
        placeholder="Comments"
        :modelValue="classModal.data?.comment_description"
      />
    </div>
    <router-link
      class="btn text-primary-1 border-primary-1 bg-hover-light-primary-1 p-2 w-100"
      :to="'/teachers/students/'+classModal.data?.studentId"
    >
      <i class="pi pi-user"></i>
      See student’s profile
    </router-link>
    <a
      :href="classModal.data.linkZoom"
      target="_blank"
      class="btn text-primary-1 mt-3 border-primary-1 bg-hover-light-primary-1 p-2 w-100"
    >
      <i class="pi pi-user"></i>
        Go to my class
    </a>
  </Dialog>
</template>

<script>
import useCalendarTeacher from "@/modules/teachers/composables/MyCalendar/useCalendarTeacher";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import { ref } from "vue";
export default {
  setup() {
    const { classModal , modalClass} = useCalendarTeacher();
    return {
      classModal,
      formatDate,
      modalClass
    };
  },
};
</script>
