<template>
  <div>
    <div
      class="
        bg-primary-5
        border-r-10
        p-4
        mb-4
        d-flex
        align-items-center
        justify-content-between
      "
    >
      <div class="w-100">
        <h3 class="text-primary-0 fw-bold">
          Here, you will find your schedule for the week
        </h3>
        <p class="py-2">
          Click on each card to see your student's profile and level.
        </p>
      </div>
      <div class="field-outline-blue w-50 text-end">
        <!-- v-model="rangeDates" -->
        <!-- <Calendar
          id="icon"
          class="bg-transparent calendar-small fw-vold"
          dateFormat="M dd"
          selectionMode="range"
          :manualInput="false"
          :showIcon="true"
          placeholder="Week"
        /> -->
      </div>
    </div>
    <class-info-modal />
    <skeleton
      width="100%"
      height="50vh"
      v-if="classesWeek.isLoading"
      borderRadius="10px"
      class="skeleton-dark"
    />
    <calendar-component
      :events="events"
      v-else
      @onEventClick="onEventClick"
      title="Schedule for the week"
      class="teacher-cal"
    />
  </div>
</template>

<script>
import useCalendar from "@/shared/composables/Calendar/useCalendar";
import CalendarComponent from "@/shared/components/Calendar/CalendarComponent";
import ClassInfoModal from "@/modules/teachers/components/Calendar/ClassInfoModal";
import CalendarCreateEventComponent from "@/shared/components/Calendar/CalendarCreateEventComponent";
import { onBeforeMount, onMounted, ref } from "vue";
import useCalendarTeacher from "@/modules/teachers/composables/MyCalendar/useCalendarTeacher";
import "./styles.scss";
// import ClassInfoModal from '../../components/Calendar/ClassInfoModal.vue';

export default {
  name: "CalendarView",
  components: {
    CalendarComponent,
    CalendarCreateEventComponent,
    ClassInfoModal,
  },

  setup() {
    const { loadServices, classesWeek, events, modalClass } =
      useCalendarTeacher();

    const date = ref('')

    loadServices();

    const { handleClientLoad } = useCalendar();
    onBeforeMount(() => {
      handleClientLoad();
    });

    const onEventClick = (event) => {
      if(event.startText+event.endText === date.value){
        date.value = ''
        modalClass(event);
        return
      }
      date.value = event.startText+event.endText
    };

    return {
      ...useCalendar(),
      classesWeek,
      events,
      onEventClick,
      // EVENTS,
    };
  },
};
</script>
